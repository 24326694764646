

















































































































































































































































































































































































































































































































































































































































































































.country-selector {
  padding: 0;
  .country-selector__label {
    display: none;
  }
  input {
    border: 0;
    border-bottom: 2px solid #e9ecef;
    border-radius: 0;
    height: 40px;
    font-size: clamp(15px, 1vw, 16.2px);
    font-weight: 600;
    font-size: 18px;
    color: #23222f;
    &:focus {
      box-shadow: unset;
      border-bottom: 2px solid #009751;
    }
    &::placeholder {
      font-weight: 500;
      font-size: clamp(13px, 1vw, 15px);
    }
  }
}
.input-phone-number {
  padding-top: 0px;
  .input-tel__label {
    margin-bottom: auto;
    color: #98a6ad !important;
    font-size: 11px !important;
  }
  input.input-tel__input {
    font-size: 16px !important;
    color: #23222f;
    caret-color: #23222f !important;
    &:focus {
      box-shadow: unset !important;
      border-bottom: 2px solid #009751 !important;
    }
    &::placeholder {
      font-weight: 500;
      font-size: clamp(13px, 1vw, 15px);
    }
  }
}
.birthday-input {
  display: flex;
  justify-content: center;
  align-items: center;
  .day,
  .month,
  .year {
    flex: 1;
    text-align: center;
    label {
      font-size: 12px !important;
      font-weight: 600;
      margin-bottom: 0px !important;
    }
  }
  input {
    border-right: 2px solid #00000038 !important;
    text-align: center;
    &::placeholder {
      font-weight: 600;
    }
    appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
.box-kyc {
  width: 100%;
  min-height: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 7px 10px 2px #00000038;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 1.25rem;
    background-color: #009750;
    border-radius: 10px 10px 10px 10px;
    .text {
      color: #ffff;
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
      .text-warning {
        font-size: clamp(15px, 2vw, 16px);
      }
    }
    .icon {
      cursor: pointer;
      img {
        height: 20px;
        width: auto;
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .content-box {
    padding: 1.25rem;
    .text-error {
      color: red;
      font-size: 13px;
      font-weight: 700;
      text-transform: capitalize;
    }
    .form-group {
      label {
        font-weight: 600;
        color: #6c757d;
        font-size: 16px;
        margin-bottom: 5px;
      }
      select,
      input {
        width: 100%;
        border: 0;
        border-bottom: 2px solid #e9ecef;
        border-radius: 0;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        font-size: 18px;
        color: #23222f;
        &:focus {
          box-shadow: unset;
          border-bottom: 2px solid #009751;
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
      .form-control {
        border: 0;
        border-bottom: 2px solid #e9ecef;
        border-radius: 0;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        font-size: 18px;
        color: #23222f;
        &:focus {
          box-shadow: unset;
          border-bottom: 2px solid #009751;
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
    }
    .close {
      color: #fff;
    }
    .removi-img {
      position: absolute;
      top: calc(50% - 15px);
      left: 0;
      right: 0;
      margin: auto;
      background: #f3d91f;
      border: 1px #f3d91f solid;
      border-radius: 5px;
      padding: 7px 20px;
      opacity: 0;
      z-index: 20;
      width: 200px;
      transition: 0.3s;
      transform: translateY(100%);
      color: #000 !important;
    }
    .show-img:hover .removi-img {
      transform: translateY(0);
      opacity: 1;
    }
    .show-img {
      padding: 10px !important;
      height: auto !important;
      position: relative !important;
    }
    .show-img img {
      position: relative;
      z-index: 10;
      max-width: 225px;
    }
    .show-img {
      &:hover {
        &::after {
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          z-index: 11;
          content: '';
        }
      }
    }
    svg {
      font-size: 30px;
    }
    #uploadImage {
      .avatarEmpty {
        background: #1111111f;
        padding: 45px 0;
        color: #424242;
        border-radius: 10px;
        height: 180px;
        margin-bottom: 23px;
        width: 100%;
        position: relative;
        cursor: pointer;
        text-align: center;

        .fileUpload {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 22;
          outline: none;
          opacity: 0;
        }

        p {
          font-size: 20px;
          font-weight: bold;
          margin-top: 20px;
          margin-bottom: 0;
        }
      }

      .imgInfo {
        text-align: center;
        margin-bottom: 20px;

        .colorGreen {
          color: #25e69c;
          margin: 0 10px;
        }
      }
    }
  }
}
.box-change-img {
  .title-section {
    border-bottom: 2px solid #009751;
    border-top: 2px solid #009751;
    text-align: center;
    font-weight: 600;
  }
  .nav-kyc {
    display: flex;
    justify-content: center;
    margin: 15px auto;
    .item {
      border: 1px solid #009750;
      box-shadow: 0 2px 6px 0 #ccc;
      margin: 5px;
      padding: 5px 15px;
      display: block;
      width: 145px;
      text-align: center;
      border-radius: 15px;
      color: #23222f;
      font-weight: 600;
      cursor: pointer;
      &.active {
        background: #009750;
        color: #fff;
      }
    }
  }
}
